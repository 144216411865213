import React from 'react';
import footerBackground1 from '../../assets/footerBackground.svg'
import footerBackground2 from '../../assets/footerBackground2.svg'
import footerBoreder from '../../assets/FooterBorder.svg'
import logo from '../../assets/Group 28.svg'
import linkedinIcn from '../../assets/Frame 42.svg'
import Xlogo from '../../assets/x_logo.svg'
import faceBookLogo from '../../assets/Symbol.svg'
const Footer: React.FC = () => {
  return (
    <footer id='contact' className="relative bg-gray-800 text-white py-10 px-4 rounded-lg mx-4">
        <img src={footerBackground1} alt="" className='absolute top-0 left-0'/>
      <div className="max-w-6xl mx-auto text-center">
        <button className="bg-gray-600 text-sm font-semibold text-white py-2 px-4 rounded-full mb-6">
          Get in Touch Section
        </button>
        <h2 className="text-3xl font-bold mb-4">
          Ready to <span className="text-blue-500">Grow with Us?</span>
        </h2>
        <p className="text-gray-400 mb-6">
          Want to find out how Thinkelution can help your business with the latest technology? Get in touch with us today, and we'll show you how we can make a difference.
        </p>
        <div className='inline-block bg-blue-700 text-white py-1 px-4 rounded-lg mb-8'>
          <span>Email us at: </span>
        <a href="mailto:vivek@thinkelution.com" className="underline">
        vivek@thinkelution.com
        </a>
        </div>

      </div>
      <img src={footerBoreder} alt='border' className='mx-auto'/>
      <div className="mt-6 mx-20 flex items-center justify-between">
          <div className="flex items-center">
            <img src={logo} alt="Thinkelution Logo" className="w-8 h-auto" />
            <span className="relative top-2 text-xl font-semibold  ">Thinkelution</span>
            
          </div>
          <div className="flex space-x-4">
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:opacity-75 z-10">
  <img src={faceBookLogo} alt="Facebook" className="h-10 w-10" />
</a>
            <a href="https://twitter.com" aria-label="X" className="hover:opacity-75 z-10">
              <img src={Xlogo} alt="X" className="h-10 w-10" />
            </a>
            <a href="https://linkedin.com" aria-label="LinkedIn" className="hover:opacity-75 z-10">
              <img src={linkedinIcn} alt="LinkedIn" className="h-10 w-10" />
            </a>
          </div>
        </div>
      <img src={footerBackground2} alt="" className='absolute bottom-0 right-0'/>
    </footer>
  );
};

export default Footer;
