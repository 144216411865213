import React, { useState } from 'react';
import logo from '../../assets/Group 28.svg';
import star from '../../assets/stars.svg';

const Header: React.FC = () => {
  const [activeSection, setActiveSection] = useState('home');

  const handleScroll = (section: string) => {
    setActiveSection(section);
  };

  return (
    <header className="fixed bg-white shadow-md py-3 z-50 w-full">
      <div className="container mx-auto px-16 flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="w-8 h-auto" />
          <span className="text-xl font-bold relative -left-1 -bottom-2">Thinkelution</span>
        </div>
        <nav className="flex items-center space-x-5">
          <a
            href="#home"
            onClick={() => handleScroll('home')}
            className={activeSection === 'home' ? 'text-blue-600 border-b-4 border-blue-600' : 'text-gray-700 hover:text-blue-600'}
          >
            Home
          </a>
          <a
            href="#services"
            onClick={() => handleScroll('services')}
            className={activeSection === 'services' ? 'text-blue-600 border-b-4 border-blue-600' : 'text-gray-700 hover:text-blue-600'}
          >
            Software Consulting
          </a>
          <a
            href="#products"
            onClick={() => handleScroll('products')}
            className={activeSection === 'products' ? 'text-blue-600 border-b-4 border-blue-600 flex items-center' : 'text-gray-700 hover:text-blue-600 flex items-center'}
          >
            Our Products
            <span className="ml-1"><img src={star} alt="stars" /></span>
          </a>
          <a
            href="#contact"
            onClick={() => handleScroll('contact')}
            className={activeSection === 'contact' ? 'text-blue-600 border-b-4 border-blue-600' : 'text-gray-700 hover:text-blue-600'}
          >
            Contact Us
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
