import React from 'react';
import softwareDevIcn from '../../assets/software-license (1).svg'
import cloudIcn from '../../assets/cloud (1).svg'
import systemArchitectureIcn from '../../assets/designtools.svg'
import dataIntegrationIcn from '../../assets/database (1).svg'
import rightArrow1 from '../../assets/Arrow_right1.svg'
import rightArrow2 from '../../assets/Arrow_right.svg'
import backgroundImg from '../../assets/afa3b9f5871ac0f234763735362fd42e.png'
import supportBg from '../../assets/servicessupportbackground.svg'
const Services: React.FC = () => {
  return (
    <div id='services' className="relative bg-cover bg-no-repeat bg-center px-14 text-white min-h-full p-10" style={{
      backgroundImage: `url(${backgroundImg})`,
      backgroundColor: 'lightgray',
    }}>
      <img src={supportBg} className='absolute bottom-0 left-0' />
      <div className="container  flex justify-between">
        <div className="xl:max-w-2xl lg:max-w-xl  px-8">
          <div className="my-10">
            <button className="bg-transparent text-white py-2 px-4 rounded-full border border-white">
              We Simplify Technology for You
            </button>
          </div>

          <h1 className="text-4xl font-bold mb-4">Smart Software Solutions for Every Business</h1>
          <p className="xl:text-lg leading-relaxed ">
            Our software consulting services help businesses navigate the complexities of technology.
            From system architecture design to custom software development, we provide tailored
            solutions that optimize processes, reduce costs, and accelerate growth.
          </p>
          <div className=" relative mt-28">
            <div className="bg-white/10 py-3 px-6 rounded-lg text-lg">
              See How We Can Help
              <img src={rightArrow2} className='absolute right-5 top-3.5' />
            </div>
          </div>
        </div>

        <div className="container xl:ml-20  grid grid-cols-2 gap-6">
          <div className=" bg-white text-blue-800 p-4 rounded-tl-3xl shadow-lg transform hover:scale-105 transition-transform duration-300">
            <div className="flex-col items-center ">
              <div className="bg-custom-gradient-service p-4 rounded-lg inline-block">
                <img src={softwareDevIcn} />
              </div>
              <h3 className=" relative xl:text-xl mt-12 lg:text-lg font-semibold">Custom <br />Software <br /> Development <img src={rightArrow1} className='absolute 2xl:left-[30%] xl:left-[50%] left-[60%] top-0' /></h3>
            </div>
          </div>
          <div className=" bg-white/10 p-4 rounded-tl-3xl shadow-lg transform hover:scale-105 transition-transform duration-300">
            <div className="flex-col items-center">
              <div className="bg-white/10 p-4 rounded-lg inline-block">
                <img src={systemArchitectureIcn} />
              </div>
              <h3 className="mb-0 relative xl:text-xl mt-12  lg:text-lg font-semibold">Enterprise <br />Systems <br /> Architecture <img src={rightArrow2} className='absolute 2xl:left-[38%] xl:left-[64%] left-[80%] top-0' /></h3>
            </div>
          </div>
          <div className=" bg-white/10 p-4 rounded-tl-3xl shadow-lg transform hover:scale-105 transition-transform duration-300">
            <div className="flex-col items-center">
              <div className="bg-white/10 p-4 rounded-lg inline-block">
                <img src={cloudIcn} />
              </div>
              <h3 className=" relative xl:text-xl mt-12 lg:text-lg font-semibold">Cloud <br />Solutions<img src={rightArrow2} className='absolute 2xl:left-[23%] xl:left-[38%] left-[45%] top-0' /></h3>
            </div>
          </div>
          <div className=" bg-white/10 p-4 rounded-tl-3xl shadow-lg transform hover:scale-105 transition-transform duration-300">
            <div className="flex-col items-center ">
              <div className="bg-white/10 p-4 rounded-lg inline-block">
                <img src={dataIntegrationIcn} />
              </div>
              <h3 className=" relative xl:text-xl mt-12 lg:text-lg font-semibold">Data <br />Integration & <br />Management<img src={rightArrow2} className='absolute 2xl:left-[18%] xl:left-[30%] left-[45%] top-0' /></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
