import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import  Home  from './routes/HomePage/Home';
import Footer from './Components/Footer/Footer';

function App() {
  return (
   <>
  
   <BrowserRouter>
   <Header />
   <Routes>
    <Route path='/' element={<Home />} />
   </Routes>
   <Footer />
   </BrowserRouter>
   </>
  );
}

export default App;
