import Header from "../../Components/Header/Header"
import DescriptionComponent from "./Description"
import FunLearning from "./FunLearning"
import ProductPage from "./OurProducts"
import Services from "./Services"

 const Home:React.FC=()=>{
    return <div>
        <DescriptionComponent />
        <Services />
         <ProductPage /> 
        <FunLearning /> 
    </div>
}
export default Home