import React from 'react';
import leftImg from '../../assets/2a647b4273fc9f02396caf1506e3c28f.png';
import rightImg from '../../assets/577956b53f35c439bf79281187cefe69.png';

const FunLearning: React.FC = () => {
  return (
    <div className="w-full">
      <div className="bg-[#F9F9FB] py-20 px-20 grid grid-cols-1 md:grid-cols-2 gap-10">
        <div className="flex flex-col gap-6">
          <div>
            <h2 className="text-2xl md:text-3xl font-bold mb-4">
              Fun Learning with <span className="text-orange-500">STEM</span>
            </h2>
            <p className="text-gray-700">
              We offer exciting STEM learning programs that let kids explore technology in a fun way. 
              From coding workshops to building robots, our classes encourage creativity and critical thinking.
            </p>
          </div>
          <div className="relative">
            <img
              src={leftImg}
              alt="Kids learning with VR"
              className="w-full object-cover rounded-lg aspect-[4/3]"
            />
          </div>
        </div>
        <div className="flex items-start justify-center">
          <div className="relative h-full">
            <img
              src={rightImg}
              alt="STEM project"
              className="w-full h-full object-cover rounded-lg aspect-[4/3]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunLearning;
