import React from 'react';
import DescriprionLogo1 from '../../assets/606af89c9f2cc41ce3a572c6a6238284.png'
import DescriprionLogo2 from '../../assets/159c4a979dec9490eef96d9e2b1ae5cf.png'
import backgroundImg from '../../assets/descriptiveBackground.svg'
const DescriptionComponent: React.FC = () => {
  return (
    <div id='home' className="relative bg-gray-50 pt-16 overflow-hidden">
      <img
        src={backgroundImg}
        alt="background1"
        className="absolute -top-20 left-5 z-1 opacity-50"
      />
      <div className="relative z-10 flex flex-col items-center justify-center  space-y-6 px-4 pt-10 pb-32">
        <div className="text-sm text-gray-500 bg-white rounded-full px-4 py-2 shadow-md">
          Welcome to Thinkelution Consultancy Pvt Ltd
        </div>
        <h1 className="text-4xl font-bold text-center text-gray-900">
          Empowering Businesses with <br />Cutting-edge 
          <span className="text-blue-600"> Technology Solutions</span>
        </h1>
        <p className=" text-gray-600 text-center max-w-2xl">
          Brief intro about the company, focusing on its expertise in software consulting, AI innovation,
          and video streaming.
        </p>
        <a
          href="#contact"
          className="bg-black text-white font-semibold py-3 px-8 rounded-lg shadow-lg hover:bg-gray-800 transition-colors"
        >
          Learn More
        </a>
        <div className="absolute left-20 bottom-0 w-56 h-56">
          <img
            src={DescriprionLogo1}
            alt="3D Icon"
            className="object-cover rounded-lg"
          />
        </div>

        <div className="absolute xl:-right-32 xl:-top-32 xl:w-description-custom xl:h-description-custom lg:w-80 lg:h-80 lg:-top-14 lg:-right-28">
          <img
            src={DescriprionLogo2}
            alt="Star Icon"
            className="object-cover rounded-lg"
          />
        </div>
      </div>
    </div>
  );
};

export default DescriptionComponent;