import React from 'react';
import vector1 from '../../assets/vectorProducts.svg'
import vector2 from '../../assets/vectorProducts2.svg'
import productbg from '../../assets/productbg.svg'
import analyzerLogo from '../../assets/9b8a80e34f2dd82abc3f216f7710236d.jpeg'
const ProductPage: React.FC = () => {
  return (
    <div id='products' className="min-h-screen bg-gray-50 py-5 px-4">
      <div className='flex justify-center items-center my-8 overflow-hidden'>
        <img src={vector1} />
        <div className=' flex-shrink-0  mx-1'>Our Top Products</div>
        <img src={vector2} />
      </div>
      <header className="text-center mb-10">
        <h1 className="text-4xl font-bold text-gray-800 ">
          Unlock the Future with Our <span className='bg-custom-radial-product bg-clip-text text-transparent'>Best-in-Class</span> Products
        </h1>
        <p className="text-lg text-gray-500 mt-2">
          AI and Streaming Solutions Designed for the Modern Business
        </p>
      </header>
      <section className="relative bg-white shadow-lg border border-green-500 rounded-3xl text-center pt-8 mx-10 2xl:mx-26 overflow-hidden my-20 ">
        <div className='relative z-10'>
          <h2 className="text-3xl font-bold mb-4 flex items-center justify-center">
            Videolution <span className="text-green-600 ml-2">Analyzer</span>
          </h2>
          <p className="text-gray-600 mb-4 xl:mx-52 mx-32">
            Videolution Analyzer is our AI-driven video analysis tool that gives you
            deep insights from your video content in real-time. Whether you're tracking
            user engagement or detecting unusual patterns, this tool helps you make
            data-driven decisions.
          </p>
          <button className='px-3 py-2 bg-green-600 text-white rounded-lg hover:cursor-pointer hover:bg-green-800 mb-8'>
            Explore
          </button>
          <div className='w-[60%] h-[16rem] xl:h-[20rem] 2xl:h-[22rem] mx-auto overflow-hidden border border-t-green-500 border-x-green-500 rounded-t-2xl'> {/* Adjust height as needed */}
            <img src={analyzerLogo} alt="" className='w-full ' />
          </div>
        </div>
        <div className='absolute right-0 top-10 z-0'>
          <img src={productbg} alt="" />
        </div>
      </section>
      <section className="relative bg-white shadow-lg border border-customIndigo rounded-3xl text-center pt-8 mx-10 2xl:mx-26 overflow-hidden mb-10">
        <div className='relative z-10'>
          <h2 className="text-3xl font-bold mb-4 flex items-center justify-center">
            Videolution <span className="text-customIndigo ml-2">Suite</span>
          </h2>
          <p className="text-gray-600 mb-8 xl:mx-52 mx-32 ">
            Videolution Suite is our comprehensive video streaming platform designed to deliver high-quality, reliable streaming for both live and on-demand content. With multi-bitrate support and scalable servers, you can ensure your audience gets the best experience, no matter where they are
          </p>
          <div className='w-[60%] h-[16rem] xl:h-[20rem] 2xl:h-[22rem] mx-auto overflow-hidden border border-t-customIndigo border-x-customIndigo rounded-t-2xl'>
          </div>
        </div>
      </section>

    </div>
  );
};

export default ProductPage;
